<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between">
          <h4>Grupos</h4>
        </div>

        <div class="d-flex justify-content-end">
          <div>
            <search-input
              v-model="searchGroup"
              :is-loading-search="isLoadingSearch"
              @enter="search"
            />
          </div>
          <b-button
            class="ml-2 pb-2 mb-2"
            variant="primary"
            @click="showCreateGroupModal"
          >
            CRIAR GRUPO
          </b-button>
        </div>

        <b-card-text class="d-flex flex-column">
          <b-table
            :items="allGroups"
            :fields="groupFields"
            :busy="isLoading || isLoadingSearch"
            bordered
            striped
            hover
          >
            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                class="mr-1"
                title="Editar grupo"
                @click="showEditGroupModal(row.item)"
              >
                <b-icon icon="pencil"></b-icon>
              </b-button>
              <b-button
                size="sm"
                title="Desativar grupo"
                @click="showDeactiveGroupModal(row.item)"
              >
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>

          <b-button v-if="showButton" class="block" @click="getMore"
            >CARREGAR MAIS
            <b-icon
              v-if="isLoading"
              icon="arrow-clockwise"
              animation="spin"
            ></b-icon>
          </b-button>
        </b-card-text>

        <b-modal
          v-model="editGroupModal"
          title="Insira as informações do grupo"
          hide-footer
        >
          <b-form @submit.prevent="updateGroup">
            <form-input
              v-model="groupInfo.group"
              icon="pencil"
              label="Grupo"
              :errors="errors['group']"
              required
            />

            <form-input
              v-model="groupInfo.code"
              icon="card-heading"
              label="Código"
              :errors="errors['code']"
              required
            />

            <div class="d-flex justify-content-end">
              <b-button type="submit">SALVAR</b-button>
            </div>
          </b-form>
        </b-modal>
        <b-modal
          v-model="createGroupModal"
          title="Insira as informações do grupo"
          hide-footer
        >
          <b-form @submit.prevent="createGroup">
            <form-input
              v-model="groupInfo.group"
              icon="pencil"
              label="Grupo"
              :errors="errors['group']"
              required
            />

            <form-input
              v-model="groupInfo.code"
              icon="card-heading"
              label="Código"
              :errors="errors['code']"
              required
            />

            <div class="d-flex justify-content-end">
              <b-button type="submit">SALVAR</b-button>
            </div>
          </b-form>
        </b-modal>

        <b-modal
          v-model="deactiveGroupModal"
          title="Desativar grupo"
          hide-footer
        >
          <p>
            Deseja desativar o grupo "{{ groupInfo.group }}" permanentemente?
          </p>
          <div class="d-flex justify-content-end">
            <b-button @click="deactiveGroup">DESATIVAR</b-button>
          </div>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";
import FormInput from "../../components/form/FormInput.vue";
import SearchInput from "../../components/shared/SearchInput.vue";

export default {
  components: { FormInput, SearchInput },
  data() {
    return {
      page: 2,
      isLoading: false,
      showButton: true,
      searchGroup: "",
      isLoadingSearch: false,
      groupInfo: {
        code: "",
        group: "",
      },
      errors: {},
      allGroups: [],
      editGroupModal: false,
      createGroupModal: false,
      groupId: null,
      deactiveGroupModal: false,
      groupFields: [
        {
          key: "group",
          label: "Grupo",
        },
        {
          key: "code",
          label: "Código",
          thStyle: { width: "150px !important" },
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: { width: "100px !important" },
        },
      ],
    };
  },

  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess", "setOverlay"]),

    showEditGroupModal(item) {
      this.errors = {};
      this.groupInfo.code = item.code;
      this.groupInfo.group = item.group;
      this.groupId = item.id;
      this.editGroupModal = true;
    },

    showCreateGroupModal() {
      this.errors = {};
      this.groupInfo = {
        code: "",
        group: "",
      };

      this.createGroupModal = true;
    },

    showDeactiveGroupModal(item) {
      this.errors = {};
      this.groupInfo.group = item.group;
      this.groupId = item.id;
      this.deactiveGroupModal = true;
    },

    async getMore() {
      if (this.searchGroup) {
        this.isLoadingSearch = true;

        const { data } = await http(`/group`, {
          params: {
            search: this.searchGroup,
            page: this.page,
          },
        });

        this.allGroups = [...this.allGroups, ...data];
        this.isLoadingSearch = false;
        this.showButton = data.length === 20;
      } else {
        this.isLoading = true;
        const { data } = await http(`/group?page=${this.page}`);
        this.allGroups.push(...data);
        this.showButton = data.length === 20;
        this.isLoading = false;
      }
      this.page += 1;
    },

    async search() {
      this.page = 2;
      if (this.searchGroup) {
        this.isLoadingSearch = true;

        const { data } = await http(`/group`, {
          params: {
            search: this.searchGroup,
          },
        });

        this.allGroups = data;
        this.isLoadingSearch = false;
        this.showButton = data.length === 20;
      } else {
        this.isLoading = true;
        const { data } = await http(`/group`);
        this.allGroups = data;
        this.isLoading = false;
        this.showButton = data.length === 20;
      }
    },

    async updateGroup() {
      try {
        this.errors = {};
        const { data } = await http.put(
          `/group/${this.groupId}`,
          this.groupInfo
        );
        const index = this.allGroups.findIndex((e) => e.id === data.id);
        this.allGroups.splice(index, 1, data);
        this.editGroupModal = false;
        this.setToastedSuccess({
          message: "Grupo atualizado com sucesso",
        });
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToastedError({
            message: err.response.data.error,
          });
        }
      }
    },

    async createGroup() {
      try {
        this.errors = {};
        const { data } = await http.post(`/group`, this.groupInfo);
        this.allGroups.push(data);
        this.createGroupModal = false;
        this.setToastedSuccess({
          message: "Grupo criado com sucesso",
        });
      } catch (err) {
        if (err.response.status === 406) {
          this.errors = err.response.data.error;
        } else {
          this.setToastedError({
            message: err.response.data.error,
          });
        }
      }
    },

    async deactiveGroup() {
      this.deactiveGroupModal = false;
      this.setOverlay(true);
      await http.put(`/group/deactive/${this.groupId}`);

      const groups = this.allGroups.filter(
        (group) => group.id !== this.groupId
      );

      this.allGroups = groups;
      this.setOverlay(false);
      this.setToastedSuccess({
        message: "Grupo desativado com sucesso",
      });
    },
  },

  async created() {
    const { data } = await http("/group?page=1");
    if (data.length < 20) {
      this.showButton = false;
    }
    this.allGroups.push(...data);
  },
};
</script>
